import React, { ChangeEvent, useEffect, useState } from "react";


import {useParams } from "react-router-dom";

import { useRecoilState } from "recoil";
import {storeState, loadingState, userState } from "../../../state";
import { getAiRequestListByStore, uploadImages } from "../../../api/api";

import { useTranslation } from "react-i18next";
import { timePeriodFormatter } from "../../../utils/timePeriodFormatter";
import { Button, List, Text } from "@telegram-apps/telegram-ui";
import { CameraAlt } from "@mui/icons-material";
import { Box } from "zmp-ui";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Snackbar } from "@telegram-apps/telegram-ui";

interface AiRequest {
  status: string;
  created_at: string;
}


const ScaneMenuPage: React.FC = () => {
  const { t } = useTranslation("global");
  const { store_uuid } = useParams<{ store_uuid: string }>();
  const [aiRequests, setAiRequests] = useState<AiRequest[]>([]);
  const [user, ] = useRecoilState(userState);
  const [, setImage] = useState<string>("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState<"success" | "error">("success");
  // const snackbar = useSnackbar();

  const [store] = useRecoilState(storeState);
  const [loading, setLoading] = useRecoilState(loadingState);

  useEffect(() => {
    setLoading({ ...loading, isLoading: true });
    fetchAiRequestList();
  }, [store_uuid]);

  const fetchAiRequestList = async () => {
    if (!store_uuid) return;
    const data = await getAiRequestListByStore(store_uuid);
    if (!data?.error) {
      setLoading({ ...loading, isLoading: false });
      setAiRequests(data.data);
    } else {
      setLoading({ ...loading, isLoading: false });
      console.error("Error fetching ai requests:", data.error);
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const files = target.files;
  
    if (files && files.length > 0) {
      const file = files[0]; 
  
      try {
        const response = await uploadImages(store.uuid, user.uuid, [file]);
        console.log("Upload successful:", response);
  
        const data = response.data.data;
        const url = data?.urls?.[0] || ""; 
  
        console.log("url", url);
        setImage(url);
        setSnackbarMessage(t("snackbarMessage.uploadImageSuccess"));
        setSnackbarType("success");
        setSnackbarOpen(true);
        fetchAiRequestList();
      } catch (error) {
        console.error("Upload failed:", error);
        setSnackbarMessage(t("snackbarMessage.uploadImageFail"));
        setSnackbarType("error");
        setSnackbarOpen(true);
        fetchAiRequestList();
      }
    } else {
      console.log("No files selected.");
    }
  };

  return (
    <div className="page">
      <div
        className="section-container"
        style={{ backgroundColor: "#f3f3f3", height: "95vh" }}
      >
        <div>
          <div style={{ textAlign: "center" }}>
            <div>
              <Text style={{ fontSize: "20px", color: "black" }}>
                {t("productManagement.scanMenu.title")}
              </Text>
            </div>
            <Text style={{ color: "black" }}>
              {t("productManagement.scanMenu.description")}
            </Text>
            <Text style={{ color: "black" }}>
              {t("productManagement.scanMenu.current_count")}:{" "}
              {store.ai_requests_count}/5
            </Text>
          </div>

          <div style={{ marginTop: "5px", padding: "10px 10px" }}>
            {store.ai_requests_count !== undefined &&
            store.ai_requests_count > 4 ? (
              <div style={{ textAlign: "center" }}>
                <Text
                  className="red-color"
                  style={{ fontSize: "20px", color: "black" }}
                >
                  {t("productManagement.scanMenu.over_limit")}
                </Text>
              </div>
            ) : (
              <Box>
                <Button
                  style={{
                    backgroundColor: "red",
                    marginLeft: "7px",
                    width: "92vw",
                    borderRadius: "20px",
                  }}
                  onClick={() => document.getElementById("chooseFile")?.click()}
                >
                  <CameraAlt style={{ verticalAlign: "middle" }} />
                  {t("productManagement.createProduct.uploadImage")}
                </Button>
                <input
                  type="file"
                  id="chooseFile"
                  hidden
                  onChange={handleFileChange}
                />
              </Box>
            )}
            <List style={{ marginTop: "10px" }}>
              <div style={{ display: "flex", textAlign: "center" }}>
                <Text style={{ flex: 1, fontSize: "20px", color: "black" }}>
                  {t("productManagement.scanMenu.status")}
                </Text>
                <Text style={{ flex: 1, fontSize: "20px", color: "black" }}>
                  {t("productManagement.scanMenu.request_date")}
                </Text>
              </div>
              {aiRequests.map((item) => (
                <div
                  style={{
                    backgroundColor: "#fff",
                    margin: "10px",
                    display: "flex",
                    textAlign: "center",
                  }}
                  key={item.created_at}
                >
                  <Text style={{ flex: 1, fontSize: "20px", color: "black" }}>
                    {item.status}
                  </Text>
                  <Text style={{ flex: 1, fontSize: "20px", color: "black" }}>
                    {timePeriodFormatter(item.created_at, t)}
                  </Text>
                </div>
              ))}
            </List>
          </div>
        </div>
      </div>
      <div style={{borderRadius:'10px'}}>
          {snackbarOpen && (
            <Snackbar onClose={() => setSnackbarOpen(false)} duration={3000}>
              <div className={`snackbar ${snackbarType === "success" ? "snackbar-success" : "snackbar-error"}`}>
                <div style={{display:'flex'}}>
                  {snackbarType === "success" && <CheckCircleIcon style={{ marginRight: 8, color:'green' }} />} 
                  {snackbarType === "error" && <ErrorIcon style={{ marginRight: 8, color:'red' }} />} 
                  {snackbarMessage}
                </div>
              </div>
            </Snackbar>
          )}
        </div>
    </div>
  );
};

export default ScaneMenuPage;
